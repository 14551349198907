import React from "react"

import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"

const StyledJose = styled.h4`
font-size: "1.6em !important"; 
margin-bottom: 0;
line-height: 0.8;
width: 100%;
`

export default () => (
  <StaticQuery 
    query = {graphql`
      query TonyQuery {
        tony: file(relativePath: { eq: "jose_antonio_licon.png" }) {
          childImageSharp {
            fluid(maxWidth:350) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render = { data => (
      <tony>
        <div css={{
            display: 'block !important',
            margin: '0 auto',
            marginTop:"5%",
            maxWidth: 360,
          }}>
            <div css={{
              maxWidth: 350,
              borderRadius:"50%",
              borderWidth: 1,
              borderColor: '#aaa',
              textAlign: "center",
              margin: "auto",
              marginTop:"-0%",
              overflow: "hidden",
              boxShadow: "4px 3px 8px #9A9A9A",
            }}>
              <Img css={{
              margin: 10,
              padding: 10,
              paddingTop:20,
              paddingBottom:20,
              borderRadius:"50%",
              borderWidth: 10,
            }}
            fluid={data.tony.childImageSharp.fluid} />
            </div>
            <div css={{
              margin: "auto",
              textAlign: "center",
              paddingTop:24,
              paddingBottom: 0,   
            }} >
              <p css={{
                marginBottom: 2,
                fontSize: "0.9em", 
                lineHeight:0,
              }}>PRODUCT OWNER/MANAGER/DEVELOPER</p>
              <StyledJose>JOSE ANTONIO LICON</StyledJose>
              <div css={{
                padding: 0,
                textAlign: "center",
                margin: "auto",
                borderBottom: 5,
                borderColor: 'black',
                borderWidth: 0,
                borderBottomWidth: 5,      
                borderStyle: 'solid', 
                width: "86%",
                height:10, 
              }}>&nbsp;
              </div>
            </div>
          </div>
        </tony>
    )}
  />
)
