import React from "react"
import { Link, graphql } from "gatsby"
import gray from "gray-percentage"
import Img from "gatsby-image"
import { Row, Col } from 'react-bootstrap';

import Layout from "../layouts"
import Container from "../components/container"
import { rhythm } from "../utils/typography"

import Tony from "../components/tony"
import { relative } from "path";

class IndexPage extends React.Component {
  render() {
    const data = this.props.data
    const allArticles = data.allArticles.edges.map(
      edge => edge.node
    )
    const TonyProfile = () => (
      <div css={{
        margin: 'auto',
        marginleft: "41%",
      }}><Tony /></div>

    )
    const WelcomeMessage = ({ page }) => (
      <>
        <p>Email <a href="mailto:dev@spyderboy.com">dev@spyderboy.com</a> to discuss your web or mobile app project.</p>
      </>
    )

    const PromotedCard = ({
      page,
      square = false,
      columns = 4,
      marginBottom = rhythm(1 / 2),
    }) => (
        <div>
          <h4
            css={{
              fontWeight: 400,
              marginBottom: rhythm(1 / 4),
              color: gray(50),
              position: relative,
            }}
          >
          </h4>
          <h3>{page.title}</h3>
        </div>

      )
      const AllArticles = ({ page,
        square = false,
        columns = 4,
        marginBottom = rhythm(1 / 2), 
      }) => (
        <>
        
        <Col xs={12} md={6}>
        <hr />
          <div class="mb-5">
            <Link to={page.path.alias} class="mb-2 h-100"> 
              <h3 class="text-dark mt-2">{page.title}</h3>
                <Img 
                  fluid={
                    page.relationships.image.localFile.childImageSharp.fluid
                  }
                />
            </Link>    
          </div>
        </Col>
          
            <Col xs={12} md={6}>
              <h6>SUMMARY </h6>  
              <div>{page.body.summary}</div>
              <Link to={page.path.alias}>READ MORE</Link>
            </Col>
          
        </>
      )

    return (
      <Layout>
        <TonyProfile css={{ margin: `auto`, }} />
        <div css={{ overflow: `hidden`, }}>
          <Container>
            <div
              css={{
                "@media(min-width: 800px)": {
                  display: `flex`,
                  justifyContent: `space-between`,
                },
              }}
            >
              <WelcomeMessage />
              <Row>
                {allArticles.map(page => (
                    <AllArticles
                      page={page}
                    />
                ))}
              </Row>
            </div>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allArticles: allNodeArticle(limit: 1000) {
      edges {
        node {
          title,
          fields {
            slug
          }
          path{
            alias
          }
          body {
            summary
          }
          relationships {
            image: field_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 500, maxHeight: 230) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }

      }
    }
  }
`